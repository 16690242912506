import React from 'react'
import HeaderTabs from '../../../../../headerTabs';
import BreadcrumbComponent from '../../../../../../widgets/breadcrumb';
import { useTranslation } from 'react-i18next';
import { Button, Container } from 'react-bootstrap';
import PageTitle from '../../../../../../widgets/pageTitle';
import BottomLeftWave from "../../../../../../assets/images/background/bg-bottomleft.svg";
import CasteManagementTable from '../../table';
import CasteModal from '../../addCastModal';
import BuildPagination from '../../../../../../widgets/pagination';

type Props = {
  commonProps: {
    casteData: any;
    apiStatus: any;
    cleanFormValues: any;
    toggleModalShow: any;
    setDeleteId: any;
    editHandler: any;
    updateDeleteRefresh: any;
    deleteHandler: any;
    modalShow: any;
    refreshToggle: any;
    currentCasteForUpdate: any;
    totalPages: any;
    filterUpdate: any;
    newPageRequest: any;
    getCategoriesData: any;
  }
}

const Mobile = (props: Props) => {

  const handelmodalpage: () => void = () => {
    props.commonProps.toggleModalShow(true);
    props.commonProps.cleanFormValues()
  };

  const { t } = useTranslation();
  return (
    <React.Fragment>
      {/* <Header /> */}
      <BreadcrumbComponent
        routes={[
          { name: "Home", path: "/home" },
          { name: t("common.siteAdmin"), path: "/siteadmin" },
          {
            name: t("userMgt.userMgt"),
            path: "/usermanagement",
          },
          { name: t("userMgt.mngCaste"), path: "" },
        ]}
      />
      <div className="contentarea-wrapper mb-wraper">
        <div className="contentarea-wrapper mt-3 mb-5">
          <Container fluid>
            <PageTitle pageTitle={t('userMgt.mngCaste')} gobacklink="/usermanagement" />
            <div className="filter-wrapper mt-2 umanagement-filter">
              <Button variant="primary" onClick={handelmodalpage} >{"Add Caste"}</Button>
            </div>

            <CasteManagementTable
              casteData={props.commonProps.casteData}
              apiStatus={props.commonProps.apiStatus}
              cleanFormValues={props.commonProps.cleanFormValues}
              togglemodalshow={props.commonProps.toggleModalShow}
              setDeleteId={props.commonProps.setDeleteId}
              editHandler={props.commonProps.editHandler}
              updatedeleterefresh={props.commonProps.updateDeleteRefresh}
              deleteHandler={props.commonProps.deleteHandler}
            />

            <CasteModal
              show={props.commonProps.modalShow}
              getCategoriesData={props.commonProps.getCategoriesData}
              updateAddRefresh={props.commonProps.refreshToggle}
              togglemodalshow={props.commonProps.toggleModalShow}
              currentCasteForUpdate={props.commonProps.currentCasteForUpdate}
              onHide={() => props.commonProps.toggleModalShow(false)}
            />
            <BuildPagination
              totalpages={props.commonProps.totalPages}
              activepage={props.commonProps.filterUpdate}
              getrequestedpage={props.commonProps.newPageRequest}
            />
          </Container>
        </div>
      </div>
      {/* <Footer /> */}
      <div className="bottomLeftWave">
        <img src={BottomLeftWave} alt="bottom wave" />
      </div>
    </React.Fragment>
  );

}

export default Mobile

