import React from "react";
import BreadcrumbComponent from "../../../../widgets/breadcrumb";
import PageTitle from "../../../../widgets/pageTitle";
import { Container, Row, Col } from "react-bootstrap";
import dashboardImg from "../../../../assets/images/home/dashboard.png";
import performanceImg from "../../../../assets/images/home/performance.png";
import calendarImg from "../../../../assets/images/home/calendar.png";
import gradebookImg from "../../../../assets/images/home/gradebook.png";
import attendancedImg from "../../../../assets/images/home/attendance.png";
import myhostelImg from "../../../../assets/images/home/hostel.png";
import administrationImg from "../../../../assets/images/home/siteadmin.svg";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

type Props = {}

const Mobile = (props: Props) => {
    const navigate = useNavigate();
    const menuPermission = useSelector(
      (state: any) => state.userAuthorities.permissions.menu
    );
  
    const authenticatedUserPermission = useSelector(
      (state: any) => state.authenticatedUser.permissions.menu
    );
  
    const currentUserRole = useSelector(
      (state: any) => state.globalFilters.currentUserRole
    );

  return (
     <>
      <BreadcrumbComponent routes={[{ name: "Home", path: "/home" }]} />
      <div className="contentarea-wrapper mt-3 mb-5">
        <Container fluid>
          <Row className="g-0 homeLanding">
            {((menuPermission.dashboard.canView &&
              ["student","teacher","editingteacher"].includes(currentUserRole.shortName)) ||
              (authenticatedUserPermission.dashboard.canView &&
                currentUserRole.id === 0)) && (
              <Col xs={6} md={4} lg={4}>
                <div className="homeBox" onClick={() => navigate("/dashboard")}>
                  <img src={dashboardImg} alt="Dashboard" />
                  <h4>Dashboard</h4>
                </div>
              </Col>
            )}

            {menuPermission.performance.canView && currentUserRole.id !== 0 ? (
              <Col xs={6} md={4} lg={4}>
                <div
                  className="homeBox colorBox1"
                  onClick={() => navigate("/performance")}
                >
                  <img src={performanceImg} alt="Performance" />
                  <h4>Performance</h4>
                </div>
              </Col>
            ) : (
              authenticatedUserPermission.dashboard.canView &&
              currentUserRole.id === 0 && (
                <Col xs={6} md={4} lg={4}>
                  <div className="homeBox colorBox1">
                    <img src={performanceImg} alt="Performance" />
                    <h4>Performance</h4>
                  </div>
                </Col>
              )
            )}

            {menuPermission.calendar.canView && currentUserRole.id !== 0 && (
              <Col xs={6} md={4} lg={4}>
                <div
                  className="homeBox colorBox2"
                  onClick={() => navigate("/calender")}
                >
                  <img src={calendarImg} alt="Calendar" />
                  <h4>Calendar</h4>
                </div>
              </Col>
            )}

            {menuPermission.gradebook.canView && currentUserRole.id !== 0 ? (
              <Col xs={6} md={4} lg={4}>
                <div
                  className="homeBox colorBox3"
                  onClick={() => navigate("/gradebook")}
                >
                  <img src={gradebookImg} alt="Gradebook" />
                  <h4>Gradebook</h4>
                </div>
              </Col>
            ) : (
              authenticatedUserPermission.gradebook.canView &&
              currentUserRole.id === 0 && (
                <Col xs={6} md={4} lg={4}>
                  <div className="homeBox colorBox3">
                    <img src={gradebookImg} alt="Gradebook" />
                    <h4>Gradebook</h4>
                  </div>
                </Col>
              )
            )}

            {menuPermission.attendance.canView && currentUserRole.id !== 0 ? (
              <Col xs={6} md={4} lg={4}>
                <div
                  className="homeBox"
                  onClick={() => navigate("/attendance")}
                >
                  <img src={attendancedImg} alt="Attendance" />
                  <h4>Attendance</h4>
                </div>
              </Col>
            ) : (
              authenticatedUserPermission.gradebook.canView &&
              currentUserRole.id === 0 && (
                <Col xs={6} md={4} lg={4}>
                  <div className="homeBox">
                    <img src={attendancedImg} alt="Attendance" />
                    <h4>Attendance</h4>
                  </div>
                </Col>
              )
            )}

            {currentUserRole.shortName === "student" && (
              <Col xs={6} md={4} lg={4}>
                <div
                  className="homeBox colorBox1"
                  onClick={() => navigate("/studenthosteldashboard")}
                >
                  <img src={myhostelImg} alt="My Hostel" />
                  <h4>My Hostel</h4>
                </div>
              </Col>
            )}

            {menuPermission.admin.canView ? (
              <Col xs={6} md={4} lg={4}>
                <div
                  className="homeBox colorBox1"
                  onClick={() => navigate("/siteadmin")}
                >
                  <img src={administrationImg} alt="Administration" />
                  <h4>Administration</h4>
                </div>
              </Col>
            ) : (
              ["hosteladmin", "hostelwarden"].includes(
                currentUserRole.shortName
              ) && (
                <Col xs={6} md={4} lg={4}>
                  <div
                    className="homeBox colorBox1"
                    onClick={() => navigate("/siteadmin")}
                  >
                    <img src={administrationImg} alt="Administration" />
                    <h4>Administration</h4>
                  </div>
                </Col>
              )
            )}
          </Row>
        </Container>
      </div>
    </>
  )
}

export default Mobile