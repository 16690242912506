import React, { useContext } from "react";
import { useSelector } from "react-redux";
import Home from "../../../pages/home/Home";
import UserContext from "../../context/user/user";
import { Navigate, Route } from "react-router-dom";
import AuthLogin from "../../../pages/authlogin/AuthLogin";

const Logout = React.lazy(() => import("../../../pages/logout"))
const SignUpNew = React.lazy(() => import("../../../pages/signupNew"))

const AuthRoute = () => {
  const userCtx = useContext(UserContext);
  const isLoggedIn = userCtx.isLoggedIn;
  const currentUserRole = useSelector((state: any) => state.globalFilters.currentUserRole);

  return [
    <Route
      path="/"
      element={isLoggedIn === false ? <Home /> : 
        <Navigate to={!["hosteladmin", "hostelwarden"].includes(currentUserRole.shortName) ? "/home" : "/hostelmanagement"} />
      }
    />,
    <Route path="/logout" element={<Logout />} />,
    <Route path="/authlogin" element={<AuthLogin />} />,
    <Route path="/signupnew" element={<SignUpNew />} />,
  ];
};

export default AuthRoute;