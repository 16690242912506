import React from "react";
import TabsList from "./../../tabs";
import HeaderTabs from "../../../../headerTabs";
import { Container } from "react-bootstrap";
import PageTitle from "../../../../../widgets/pageTitle";
import BreadcrumbComponent from "../../../../../widgets/breadcrumb";
import './../../style.scss';
import "./../../mobileStyle.scss";
import { BackgroundWaveBottomLeft, BackgroundWaveRight } from "../../../../../widgets/backgroundElements";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

type Props = {};

const Browser = (props: Props) => {
  const {t} = useTranslation();
  const { id, name, cid, cname } = useParams()

  return (
    <React.Fragment>
      {/* <Header /> */}
      <HeaderTabs />
      <BreadcrumbComponent
        routes={[
          { name: "Home", path: "/home" },
          { name: t('common.siteAdmin'), path: "/siteadmin" },
          { name: t('naac.coPoMgmt'), path: "/copoManagement" },
          { name: name, path: `/copoCourse/${id}/${name}`},
          { name: cname, path: "" },
        ]}
      />
      <div className="contentarea-wrapper mt-3 mb-5">
        <Container fluid>
          <PageTitle pageTitle={`${cname}: ${t('naac.configCoPoCourse')}`} gobacklink={`/copoCourse/${id}/${name}`} />
          <TabsList />
        </Container>
      </div>
      {/* <Footer /> */}
      <BackgroundWaveBottomLeft/>
      <BackgroundWaveRight/>
    </React.Fragment>
  );
};

export default Browser;
